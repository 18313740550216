<template>
  <div>
    <div class="card" v-bind:class="getClass">
      <div class="card-body">
        <i class="mr-3" v-bind:class="iconClass"></i>
        <span v-html="erp.message"></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Erp",
  props: {
    erp: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getClass() {
      return this.erp && this.erp.status ? "alert-success" : "alert-danger";
    },
    iconClass() {
      return this.erp && this.erp.status
        ? "fas fa-check-circle"
        : "fas fa-exclamation-triangle";
    },
  },
};
</script>
