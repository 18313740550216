<template>
  <div>
    <h4>{{ result.bank }}</h4>
    <hr />
    <pre>
      {{ result.data }}
    </pre>
  </div>
</template>
<script>
export default {
  name: "BankResult",
  props: {
    result: {
      default: null,
    },
  },
};
</script>
