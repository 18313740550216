<template>
  <div>
    <div class="row align-items-center">
      <div class="col-auto print" v-if="siteLogo">
        <img v-bind:src="siteLogo" alt="" width="125" />
      </div>
      <div class="col">
        <table class="table table-bordered mb-0">
          <tr scope="row">
            <th width="25%">Sipariş No</th>
            <td scope="col">{{ orderID }}</td>
          </tr>
          <tr scope="row">
            <th width="35%">Ödeme Türü / Ref. No</th>
            <td scope="col">{{ getPaymentRefNo }}</td>
          </tr>
          <tr scope="row" v-if="order.member">
            <th width="25%">Üye Ad Soyad</th>
            <td scope="col">{{ order.member.full_name }}</td>
          </tr>
          <tr scope="row" v-if="order.member && order.member.created_at">
            <th width="25%">Üyelik Tarihi</th>
            <td scope="col">{{ order.member.created_at | dateFormat }}</td>
          </tr>
          <!-- <tr scope="row">
            <th width="25%">Kargo Firması</th>
            <td scope="col">{{ getCargoName }}</td>
          </tr> -->

          <!-- Order Attachment -->
          <template v-if="detail.attachment">
            <tr scope="row" v-if="detail.attachment.delivery_date">
              <th width="25%">Teslimat Tarihi</th>
              <td scope="col">
                {{ detail.attachment.delivery_date }}
              </td>
            </tr>
            <tr
              scope="row"
              v-if="
                detail.attachment.delivery_start_time &&
                detail.attachment.delivery_due_time
              "
            >
              <th width="25%">Teslimat Saati</th>
              <td scope="col">
                {{ detail.attachment.delivery_start_time }} -
                {{ detail.attachment.delivery_due_time }}
              </td>
            </tr>
            <tr scope="row" v-if="detail.attachment.note_text">
              <th width="25%">Not</th>
              <td scope="col">{{ detail.attachment.note_text }}</td>
            </tr>
            <tr scope="row" v-if="detail.attachment.extra_note">
              <th width="25%">Ekstra Not</th>
              <td scope="col">{{ detail.attachment.extra_note }}</td>
            </tr>
            <tr scope="row" v-if="detail.attachment.note_paper">
              <th width="25%">Not Kağıdı</th>
              <td scope="col">{{ detail.attachment.note_paper }}</td>
            </tr>
            <tr scope="row" v-if="detail.attachment.image">
              <th width="25%">Sipariş Görseli</th>
              <td scope="col">
                <a v-bind:href="detail.attachment.image" target="_blank">
                  <img
                    v-bind:src="detail.attachment.image"
                    width="120"
                    class="img-fluid"
                  />
                </a>
              </td>
            </tr>
          </template>
          <!-- ./Order Attachment -->

          <tr scope="row" v-if="detail.gift.wrap">
            <th width="25%">Hediye Paketi</th>
            <td scope="col">Evet</td>
          </tr>
          <tr scope="row" v-if="detail.gift.note">
            <th width="25%">Hediye Notu</th>
            <td scope="col">{{ detail.gift.note }}</td>
          </tr>
          <tr scope="row" v-if="detail.coupon.code">
            <th width="25%">Kupon Kodu</th>
            <td scope="col">{{ order.coupon_code }}</td>
          </tr>
          <tr scope="row" v-if="detail.campaign.code">
            <th width="25%">Kampanya Kodu</th>
            <td scope="col">
              {{ detail.campaign.code }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Products -->
    <WeTable
      class="products-table"
      v-bind:index="false"
      v-bind:data="detail.products"
      v-bind:columns="productColumns"
      v-bind:actions="[]"
      v-bind:searchable="false"
      v-bind:pageDetails="false"
      v-bind:selectable="false"
      v-bind:limitable="false"
      v-bind:paginatable="false"
      v-bind:row-class="'font-14'"
      v-bind:responsive="false"
      v-bind:quantity="true"
    >
      <template slot="footer">
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">Toplam:</span>
              </div>
              <div class="col-2">
                {{ detail.price.total }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">KDV:</span>
              </div>
              <div class="col-2">{{ detail.price.tax }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">Ara Toplam:</span>
              </div>
              <div class="col-2">{{ detail.price.sub_total }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">Kargo:</span>
              </div>
              <div class="col-2">{{ detail.price.cargo }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-if="detail.coupon.discount > 0"
            v-bind:colspan="productColumns.length"
            class="border-0 text-right"
          >
            <div class="row text-danger">
              <div class="col">
                <span class="font-weight-bold">Kupon İndirimi:</span>
              </div>
              <div class="col-2">
                - {{ detail.coupon.discount | moneyFilter }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-if="detail.campaign.discount > 0"
            v-bind:colspan="productColumns.length"
            class="border-0 text-right"
          >
            <div class="row text-danger">
              <div class="col">
                <span class="font-weight-bold">Kampanya İndirimi:</span>
              </div>
              <div class="col-2">
                - {{ detail.campaign.discount | moneyFilter }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            v-bind:colspan="productColumns.length"
            class="border-0 text-right"
          >
            <div class="row">
              <div class="col">
                <span class="font-weight-bold">Genel Toplam:</span>
              </div>
              <div class="col-2">{{ detail.price.last_total }}</div>
            </div>
          </td>
        </tr>
      </template>
    </WeTable>
    <!-- <WeProduct
      title="Ürünler"
      name="product"
      v-bind:actions="[]"
      v-bind:columns="productColumns"
      v-bind:searchable="false"
      v-bind:quantity="true"
      v-bind:show-total="true"
      v-bind:show-tax="true"
      v-bind:searchable-product="false"
      v-bind:body-overflow="false"
      v-bind:campaign-discount="order.campaign.price"
      v-model="order.products"
    /> -->
    <!-- Products -->
  </div>
</template>
<script>
export default {
  name: "DetailTab",
  data() {
    return {
      productColumns: [
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "quantity", th: "Miktar", type: "integer" },
        { name: "price", th: "Birim Fiyatı", type: "currency" },
        { name: "total_price", th: "Toplam Fiyatı", type: "total_price" },
      ],
    };
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    siteLogo: {
      default: null,
    },
  },
  computed: {
    detail() {
      return this.order.detail;
    },
    orderID() {
      return this.detail.order_no || "-";
    },
    getCargoName() {
      return this.order.cargo.id ? this.order.cargo.name : null;
    },
    getPaymentRefNo() {
      let result = "";
      if (this.detail.payment_type) {
        result += this.detail.payment_type.name;
      }

      result += this.detail.ref_no ? " - " + this.detail.ref_no : "";

      return result;
    },
  },
};
</script>
